export default {
  /**
   * @description Summary - 상위 동 정보
   * */
  buildingSeq: '', // 선택된 빌딩 seq
  buildingList: [], // 빌딩 리스트

  /**
   * @description GongsilInfo - 세대 정보
   * */
  dongSeq: '', // 선택된 동 seq
  dongList: [], // 동 리스트
  gongsilList: [], // 공실 리스트
  selectFloorList: [], // 선택한 층 리스트
  selectHoList: {}, // 선택한 호 리스트

  /**
   * @description ManagerList - 관리자 정보
   * */
  keyword: '', // 관리자 검색
  managerList: [],
  selectManagerList: [],

  /**
   * @description AddedManager - 추가된 관리자 정보
   * */
  addedManagerList: [], // 추가된 관리자
  selectAddedManagerList: {}, // 선택한 추가된 관리자
};
