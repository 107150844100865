import { init } from '@rematch/core';
import { models } from '~/models';

const devtoolOptions = {};

if (process.env.NODE_ENV === 'development') {
  devtoolOptions.trace = true;
  devtoolOptions.disabled = false;
  devtoolOptions.name = '다방 HUB Development';

} else {
  devtoolOptions.disabled = true;
}

const store = init({
  models,
  redux: {
    devtoolOptions,
  },
});

export default store;
