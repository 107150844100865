import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import store from '~/store';
import useUserApi from 'web~/hooks/useUserApi';

import Alert from 'web~/components/Alert';
import { DabangBlueBtnLComponent as Button } from 'web~/components/Button';

const CorpConvertPopup = () => {
  const { dispatch } = store;
  const history = useHistory();
  const { logout } = useUserApi();
  const { corpConvertComplete } = useSelector(({ flag }) => flag);

  async function onClickLogout() {
    dispatch.flag.setFlag({ flag: 'corpConvertComplete', isShow: false });
    await logout();
    history.push({
      pathname: '/signin',
    });
  }

  const AlertBtn = () => <Button onClick={onClickLogout} label="확인" />;

  if (corpConvertComplete) {
    return (
      <Alert ButtonList={[AlertBtn]}>
        <h1>기업회원으로 전환되었습니다.</h1>
        <p>다시 로그인해주세요.</p>
      </Alert>
    );
  }

  return null;
};

export default CorpConvertPopup;
