import produce from 'immer';

import state from './state';

export default {
  state,
  reducers: {
    householdStateChange(state, payload) {
      return {
        ...state,
        household: produce(state.household, draft => {
          return {
            ...draft,
            ...payload,
          };
        }),
      };
    },
    householdBuildingUpdate(state, payload) {
      return {
        ...state,
        household: produce(state.household, draft => {
          draft.building = payload;
        }),
      };
    },
    resetForm() {
      return state;
    },
  },
  effects: dispatch => {
    return {
      async setHouseholdOwnerAttachmentsAsync({ payload }, { form }) {
        await dispatch.form.householdStateChange({
          ownerAttachments: produce(form.household.ownerAttachments, draft => {
            draft.push(payload);
          }),
        });
      },
      async removeHouseholdOwnerAttachmentsAsync({ payload }, { form }) {
        await dispatch.form.householdStateChange({
          ownerAttachments: produce(form.household.ownerAttachments, draft => {
            const idx = draft.findIndex(item => item.id === payload);

            if (idx > -1) {
              draft.splice(idx, 1);
            }
          }),
        });
      },
    };
  },
};
