import produce from 'immer';

import state, { initHouseholdFilter } from './state';

export default {
  state,
  reducers: {
    stateChange(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    itemChange(state, payload) {
      return {
        ...state,
        list: payload,
      };
    },
  },
  effects: dispatch => {
    return {
      async getHouseholdList(action, { household }) {
        const { filter } = household;

        try {
          const page = action.page || 1;
          let apiUrl = '/api/v3/gongsil';
          let name = 'list';
          let params = {
            status: action.status || action.status === '' ? action.status : filter.status,
            page,
            limit: 5,
            order: 1,
            buildingSeq: !!action.seq ? +action.seq : +filter.building,
            dong: action.dong || action.dong === '' ? action.dong : filter.dong,
            floor: action.floor || action.floor === '' ? action.floor : filter.floor,
          };

          if (window.location.pathname === '/mobile/household/request' || action.listType === 'request') {
            apiUrl = '/api/v3/gongsil/requests';
            name = 'listRequest';
            params = {
              buildingSeq: !!action.seq ? +action.seq : +filter.building,
              page,
              limit: 5,
            };
          }

          const res = await action.api(apiUrl, params);

          let newArray;
          let isMore;

          if (name === 'listRequest') {
            newArray = res;
            isMore = res.isMore;

          } else {
            newArray = {
              ...res.gongsils,
              ...res.messages,
            };
            isMore = res.gongsils.isMore;
          }

          let list = [];

          if (page === 1) {
            list = [
              ...newArray.result,
            ];

          } else {
            list = [
              ...household[name],
              ...newArray.result,
            ];
          }

          if (page === 1) {
            document.getElementById('bodyContent').scrollTo(0, 0);
          }

          dispatch.household.stateChange({
            [name]: list,
            isShowGongsilActiveMessage: res.messages ? res.messages.isShowGongsilActiveMessage : false,
            isMore: isMore,
            filter: produce(filter, draft => {
              draft.building = !!action.seq ? +action.seq : +filter.building;
              draft.dong = action.dong || action.dong === '' ? action.dong : filter.dong;
              draft.floor = action.floor || action.floor === '' ? action.floor : filter.floor;
              draft.status = action.status || action.status === '' ? action.status : filter.status;
            }),
            watchHousehold: true,
          });

        } catch (err) {
          console.error(err);
        }
      },
      async getBuildingList(action) {
        try {
          const res = await action.api('/api/v3/building/owner');

          let seq = '';
          let list = [{ label: '등록된 건물이 없습니다.', value: '' }];

          if (res.length > 0) {
            seq = action.seq ? +action.seq : res[0].seq;

            list = res.reduce((r, i) => {
              r.push({
                label: i.name,
                value: i.seq,
              });

              return r;
            }, []);
          }

          dispatch.household.stateChange({
            filter: produce(initHouseholdFilter, draft => {
              draft.building = seq;
              draft.buildingList = list;
            }),
          });

          if (seq) {
            await dispatch.household.getSummary({ seq, api: action.api });
          }

        } catch (err) {
          console.error(err);
        }
      },
      async getSummary(action, { household }) {
        const { filter } = household;

        try {
          const { dongs, floors, isBuildingManager, ...res } = await action.api(
            `/api/v3/gongsil/building/${action.seq}`);

          let dongList = [{ label: '전체 동', value: '' }];
          let floorList = [{ label: '전체 층', value: '' }];

          if (dongs.length > 0) {
            dongList = dongs.reduce((r, i) => {
              r.push({
                label: i.dongFormat,
                value: i.seq === 0 || i.seq ? i.seq : '',
              });
              return r;
            }, []);
          }

          if (floors.length > 0) {
            floorList = floors.reduce((r, i) => {
              r.push({
                label: i.floorFormat,
                value: i.seq === 0 || i.seq ? i.seq : '',
              });
              return r;
            }, []);
          }

          dispatch.household.stateChange({
            isBuildingManager,
            filter: produce(filter, draft => {
              draft.building = action.seq;
              draft.dong = '';
              draft.floor = '';
              draft.status = '';
              draft.dongList = dongList;
              draft.floorList = floorList;
            }),
            summary: res,
          });

          await dispatch.household.getHouseholdList({ api: action.api, page: 1 });

        } catch (err) {
          console.error(err);
        }
      },
    };
  },
};
