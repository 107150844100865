import React from 'react';
import { DabangBlueBtnLComponent as Button } from 'web~/components/Button';

import Alert from 'web~/components/Alert';
const ResetPassword = () => {
  let test;

  const ResetBtn = () => <Button onClick={() => {}} label="비밀번호 재설정" />;
  const NoShowBtn = () => <Button onClick={() => {}} label="6개월 간 보지 않기" cancel={true} />;

  if (test) {
    return (
      <Alert ButtonList={[NoShowBtn, ResetBtn]}>
        <h1>회원님의 소중한 개인정보보호를 위해<br/>비밀번호를 재설정해주세요.</h1>
        <p>다방허브에서는 회원님의 개인정보를 보다 안전하게 보호하기 위해</p>
        <p>주기적으로 비밀번호 재설정을 안내하고 있습니다.</p>
        <p>안전한 이용을 위해 비밀번호를 변경해주시기 바랍니다.</p>
      </Alert>
    );
  }

  return null;
};


export default ResetPassword;
