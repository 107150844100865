export default {
  household: {
    building: {
      seq: '',
      buildingSeq: '',    // 건물 seq
      name: '',           // 건물명
      road: '',           // 건물 도로명 주소
      jibun: '',          // 건물 지번 주소
      detail: '',         // 건물 상세 주소
      type: '',           // 건물타입
      typeName: '',       // 건물타입 한글명
      cityCode: '',
      stateCode: '',
    },
    gongsilName: '',    // 세대명
    dong: '',
    floor: '',
    ho: '',
    isNoinfoDong: false,
    memo: '',             // 운영자에게 남김말 메모
    agree: false, // 제 3장 제공 동의 client에서만 사용
    email: '',
    emptyBuildingList: [],


    /**
     * @description 등기부등본
     * */
    registerCopyIssueType: 0, // 0: 직접첨부, 1: 자동발급
    isNotConfirm: true,
    registerCopy: {               // 등기부등본
      url: '',
      name: '',
      id: '',
      size: '',
      contentType: '',
      sort: 0,
    },
    realEstateNo: '', // 등기부등본 자동 발급용 번호

    images: [],           // 이미지
    bedsNum: '',
    bedsInput: '',

    /**
     * @description 자동공개 설정
     * */
    isAutoActive: true,
    openSet: 0, // 0: 미지정, 1: 지정
    /** @description 중개사 미지정 */
    nearCityCodes: [],
    /** @description 중개사 지정 */
    pickAgents: [],
    agentIds: [],
    priceType: '',
    prices: [],
    occupancyType: '', // 0: 즉시 입주, 2: 날짜 선택
    occupancyDateFormat: '',
    agentMemo: '',

    /**
     * @description 세대 소유자 확인 corpType: 1, 3
     * */
    isOwner: '', // Boolean
    ownerApprovalType: '', // Number 0 : 개인 소유자, 1 : 개인 사업자, 2 : 법인 사업자
    ownerName: '',
    ownerPhone: '',
    ownerBirthday: '',
    ownerBusinessId: '',
    ownerAttachments: [],

    // 리스트 저장용
    autoRegisterCopyData: {
      count: 0,
      isMore: false,
      limit: 10,
      page: 1,
      result: [],
    },
    realEstateAddress: '정확한 주소를 선택해 주세요.',
    cities: [],
  },
  building: {},
};
