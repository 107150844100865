import React, { useEffect } from 'react';
import { element, node, func, oneOfType, string, bool, arrayOf } from 'prop-types';

import Portal from '../Portal';

import {
  Overlay, Content,
  Title, Body, BtnWrap,
} from './styled';

const ContentModalComponent = ({
  children,
  id, width, title, ButtonList,
  hideOverlay, hideClose,
  modalHandler, padding, spreadEvent,
}) => {
  useEffect(() => {
    spreadEvent && (document.body.style.overflow = 'hidden');

    return () => {
      spreadEvent && (document.body.style.overflow = '');
    };
  }, []);

  return (
    <Portal>
      <Overlay id={id || ''} hideOverlay={hideOverlay}>
        <div>
          <Content width={width}>
            <Title title={title}>
              {title}
              {!hideClose && (
                <div onClick={modalHandler}>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M24.375 5.625L5.625 24.375M5.625 5.625L24.375 24.375L5.625 5.625Z" stroke="#888888" strokeLinecap="square" />
                  </svg>
                </div>
              )}
            </Title>
            <Body padding={padding}>{children}</Body>
            {ButtonList && <BtnWrap>
              {ButtonList.map((Component, key) => <Component key={key} />)}
            </BtnWrap>}
          </Content>
        </div>
      </Overlay>
    </Portal>
  );
};

ContentModalComponent.defaultProps = {
  width: '630px',
  title: '',
  hideOverlay: false,
  hideClose: true,
  ButtonList: false,
  spreadEvent: true,
};

ContentModalComponent.propTypes = {
  children: element.isRequired,
  width: string,
  title: oneOfType([
    element,
    string,
  ]),
  hideOverlay: bool,
  hideClose: bool,
  ButtonList: oneOfType([
    bool,
    arrayOf(oneOfType([
      element,
      node,
      func,
    ])),
  ]),
  spreadEvent: bool,
};

export default ContentModalComponent;
