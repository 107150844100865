import axios from 'axios';
import Cookie from 'js-cookie';
import qs from 'query-string';

import store from '~/store';
import { deleteBlank } from '~/utils/index';
import bridge from '~/utils/bridge';

class ApiCall {
  constructor() {
    this._source = axios.CancelToken.source();

    let apiCall = axios.create({
      headers: {
        csrf: 'token',
        Accept: 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        Expires: -1,
        Pragma: 'no-cache',
      },
      cancelToken: this._source.token,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    apiCall.interceptors.request.use(this.reqConfig);
    apiCall.interceptors.response.use(this.handleSuccess, this.handleError);
    this.apiCall = apiCall;
  }

  reqConfig(config) {
    if (['post', 'put', 'patch'].includes(config.method)) {
      config.data = deleteBlank(config.data);

    } else if (config.method === 'get') {
      config.params = deleteBlank(config.params);
    }

    return config;
  }

  handleSuccess(response) {
    const { headers } = response;

    if (headers['corp-conversion-completed']) {
      const isShow = headers['corp-conversion-completed'] !== 'YES';

      store.dispatch.flag.setFlag({
        flag: 'corpConvertComplete',
        isShow,
      });

    } else if (headers['refresh-authorization']) {
      const token = headers['refresh-authorization'];

      bridge.ios(call => {
        call.callHandler('iOS Echo', {
          cmd: 'setToken',
          message: JSON.stringify({ token }),
        });
      });

      bridge.android(call => {
        call.setToken(token);
      });

      Cookie.set('jwt_token', token);
    }

    return response;
  }

  handleError = error => {
    let err;

    try {
      err = error.response.data;

    } catch (e) {
      err = error;
    }

    switch (error.response.status) {
      case 400:
        if (err.code === 426) { // app 강제업데이트
          bridge.ios(call => {
            call.callHandler('iOS Echo', {
              cmd: 'needUpdate',
            });
          });

          bridge.android(call => {
            call.showVersionCheckDialog();
          });
        }

        if (err.code === 503) {  // 긴급점검
          console.error(err);
          store.dispatch.flag.setFlag({
            flag: 'emergencyInspect',
            isShow: true,
            msg: err.msg,
          });
        }

        break;

      case 401:
        if (err.code === 401) { // 로그인 만료
          if (bridge.getPlatform() === 'web') {
            store.dispatch.error.setError({
              code: err.code,
              msg: err.msg,
              isError: true,
            });

          } else {
            bridge.ios(call => {
              call.callHandler('iOS Echo', {
                cmd: 'removeToken',
              });
            });

            bridge.android(call => {
              call.removeToken();
            });

            store.dispatch.user.resetUser();
            store.dispatch.toast.openToast({ text: err.msg });
          }

          Cookie.remove('jwt_token');
          return;
        }

        if (err.code === 470) { // 기업 전환
          bridge.ios(call => {
            call.callHandler('iOS Echo', {
              cmd: 'removeToken',
            });
          });

          bridge.android(call => {
            call.removeToken();
          });

          Cookie.remove('jwt_token');

          store.dispatch.toast.openToast({ text: err.msg });
          store.dispatch.user.resetUser();
          store.dispatch.error.setError({
            code: err.code,
            msg: err.msg,
            isError: true,
          });
          return;
        }
        break;

      case 500:
        if (typeof err === 'string') {
          err = { msg: err };

        } else if (err && err.msg) {
          err = { msg: err.msg || '에러가 발생하였습니다.' };

        } else {
          err = { msg: '#500 서버에러가 발생하였습니다.' };
        }
        break;

      default:
        if (err === null || !err.msg) {
          err = { msg: '서버에러가 발생하였습니다.' };
        }
    }

    return Promise.reject(err);
  };

  checkToken() {
    const token = Cookie.get('jwt_token');
    const platform = Cookie.get('platform');
    const version = Cookie.get('version');
    const authConfirmParam = Cookie.get('safe_auth_confirm_param');

    let headers = {};

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (platform) {
      headers['App-Platform'] = platform;
    }

    if (version) {
      headers['App-Version'] = version;
    }

    if (authConfirmParam) {
      headers['Safe-Auth-Confirm'] = authConfirmParam;
    }

    return headers;
  }

  get(path, params = {}) {
    return this.apiCall.request({
      method: 'GET',
      url: `${path}`,
      params: params,
      headers: this.checkToken(),
      responseType: 'json',
    }).then(response => {
      return Promise.resolve(response.data.result, response.status);
    });
  }

  post(path, payload = {}) {
    return this.apiCall.request({
      method: 'POST',
      url: path,
      headers: this.checkToken(),
      responseType: 'json',
      data: payload,
    }).then(response => {
      return response.data ? Promise.resolve(response.data.result, response.status) : null;
    });
  }

  destroy() {
    return this._source.cancel();
  }
}

export default new ApiCall();
