/**
 * @description 중복제거용 util 함수
 * */
const by = key => {
  const set = new Set();
  return obj => !(set.has(obj[key]) || !set.add(obj[key]));
};

/**
 * @description 중복제거함수
 * @param {String} key
 * @param {Array} arr
 * */
export const removeDuplicate = (key, arr) => arr.filter(by(key), new Set());
