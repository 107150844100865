import state from './state';

export default {
  state,
  reducers: {
    openToast(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    closeToast() {
      return state;
    },
  },
};
