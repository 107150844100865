import state from './state';

import { apiCall, errorMsg } from '~/utils';

export default {
  state,
  reducers: {
    setInfo(state, payload) {
      return {
        ...state,
        ...payload,
        loading: false,
      };
    },
    resetUser() {
      return {
        ...state,
        loading: false,
      };
    },
  },
  effects: dispatch => {
    const { user } = dispatch;

    return {
      async getUserInfo() {
        try {
          const info = await apiCall.get(`/api/v3/account`);
          user.setInfo({
            ...info,
            isLogged: true,
          });

        } catch (err) {
          console.error(err);
          apiCall.destroy();
          errorMsg(err);
        }
      },
      async logout() {
        try {
          await apiCall.post('/api/v3/auth/logout');
          user.resetUser();

        } catch (err) {
          console.error(err);
          apiCall.destroy();
        }
      },
    };
  },
};
