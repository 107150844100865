import state from './state';

export default {
  state,
  reducers: {
    setTitle(state, payload) {
      return {
        ...state,
        title: payload,
      };
    },
    setLocation(state, payload) {
      return {
        ...state,
        prevLocation: payload,
      };
    },
    setAlarm(state, payload) {
      return {
        ...state,
        isAlarm: payload,
      };
    },
    setSettings(state, payload) {
      return {
        ...state,
        isSettings: payload,
      };
    },
    setUnProcess(state, payload) {
      return {
        ...state,
        unprocess: payload,
      };
    },
  },
};
