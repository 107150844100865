import isMobile from './isMobile';

export default {
  getPlatform() {
    if (typeof Android !== 'undefined') {
      return 'android';

    } else if (typeof IOS !== 'undefined') {
      return 'ios';
      
    } else if (isMobile.Android() || isMobile.IOS()) {
      return 'mobileWeb';
      
    } else {
      return 'web';
    }
  },

  ios(callback) {
    const { WebViewJavascriptBridge, WVJBCallbacks } = window;

    if (this.getPlatform() === 'ios') {
      if (WebViewJavascriptBridge) {
        return callback(WebViewJavascriptBridge);
      }

      if (WVJBCallbacks) {
        return WVJBCallbacks.push(callback);
      }

      window.WVJBCallbacks = [callback];

      let WVJBIframe = document.createElement('iframe');
      
      WVJBIframe.style.display = 'none';
      WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
      document.documentElement.appendChild(WVJBIframe);

      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    }
  },

  android(callback) {
    const { Android } = window;

    if (this.getPlatform() === 'android') {
      if (typeof Android !== 'undefined') {
        callback(Android);
    
      } else {
        alert('bridge가 설정되지 않았습니다.');
      }
    }
  },

  web(callback) {
    if (this.getPlatform() === 'web' || this.getPlatform() === 'mobileWeb') {
      callback();
    }
  },
};
