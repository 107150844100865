const phone = (num = '') => num.replace(/(\d{3})[- ]?(\d{3,4})[- ]?(\d{4})/g, '$1-$2-$3') || '-';

const numberWithComma = x => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};

function strReverse(s = '', isJoin = false) {
  const str = s.toString();
  let temp = [];
  let tempStr = '';

  for (let n of str) {
    if (isJoin) {
      tempStr = n + tempStr;

    } else {
      temp = [
        n,
        ...temp,
      ];
    }
  }

  if (isJoin) {
    return tempStr;
  }

  return temp;
}

function cutString(str = '', size = 4) {
  const strArr = strReverse(str);
  const strSize = str.toString().length;
  
  const temp = [];
  let num = '';

  strArr.forEach((i, k) => {
    num += i;
    if ((k + 1) % size === 0) {
      const n = strReverse(num, true);
      temp.push(+n);
      num = '';

    } else if ((k + 1) === strSize) {
      const n = strReverse(num, true);
      temp.push(+n);
      num = '';
    }
  });

  return temp;
}

/**
 * 
 * @param {Number} price
 * @param {String} isMan 단위 (true: 만 단위, false: 원 단위)
 */
const priceFormat = ['', '만', '억', '조', '경'];
function convertPrice(price = '', isMan = true) {
  const prices = cutString(price);

  const result = prices.map((p, k) => {
    if (+p === 0) return '';

    let idx = k;
    if (isMan) {
      idx = ++k;
    }

    return `${numberWithComma(+p)}${priceFormat[idx]}`;
  });
  
  const priceStr = result.reduce((r, i, k) => {
    if (i === '') return r;
    if (r === '') return i;
    return `${i} ${r}`;
  }, '');

  return priceStr ? `${priceStr}원` : '';
}

export default {
  phone,
  numberWithComma,
  convertPrice,
};
