import React from 'react';
import { FullWrap, SmallWrap } from './styled';

const BI = () => {
  return (
    <svg width="107" height="46" viewBox="0 0 107 46" fill="none">
      <path fill="#4383FF" fillRule="evenodd" clipRule="evenodd" d="M0 0V30.2792H22.7099V21.6286H9.08339V8.65066H22.7099V0H0ZM28.5975 0V16.8223H37.0082V8.41069H45.4189V0H28.5975ZM84.1097 0V16.8223H92.5204V8.41069H100.931V0H84.1097ZM68.8169 0V6.48896H62.3944V0H53.8305V30.2792H77.3798V0H68.8159H68.8169ZM62.3944 21.6286H68.8169V15.1396H62.3944V21.6286ZM94.2802 36.9677C93.8146 36.9686 93.3534 36.8777 92.9229 36.7004C92.4923 36.523 92.101 36.2626 91.7711 35.934C91.4412 35.6053 91.1793 35.215 91.0003 34.7851C90.8213 34.3553 90.7287 33.8944 90.7278 33.4288C90.7299 32.4886 91.1053 31.5877 91.7715 30.9242C92.4376 30.2608 93.34 29.8891 94.2802 29.8908C95.2203 29.8893 96.1224 30.2611 96.7884 30.9246C97.4544 31.588 97.8296 32.4888 97.8317 33.4288C97.8299 34.369 97.4547 35.27 96.7888 35.9336C96.1228 36.5973 95.2204 36.9692 94.2802 36.9677ZM94.2802 21.6344C87.7402 21.6344 82.4386 26.9157 82.4386 33.4288C82.4386 39.9438 87.7392 45.2251 94.2802 45.2251C100.819 45.2251 106.121 39.9438 106.121 33.4288C106.121 26.9148 100.82 21.6344 94.2802 21.6344" />
    </svg>
  );
};

export const FullPageLoadingComponent = () => {
  return (
    <FullWrap>
      <BI />
    </FullWrap>
  );
};

export const SmallPageLoadingComponent = () => {
  return (
    <SmallWrap>
      <BI />
    </SmallWrap>
  );
};
