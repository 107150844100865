import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export default createGlobalStyle`
  ${reset};
  
  @font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 700;
    src: local('Spoqa Han Sans Bold'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Bold.woff2') format('woff2'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Bold.woff') format('woff'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 400;
    src: local('Spoqa Han Sans Regular'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Regular.woff2') format('woff2'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Regular.woff') format('woff'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 300;
    src: local('Spoqa Han Sans Light'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Light.woff2') format('woff2'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Light.woff') format('woff'),
      url('//static.dabangapp.com/font/Spoqa Han Sans Light.ttf') format('truetype');
  }
  
  * {
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
    font-family: 'Spoqa Han Sans', 'Sans-serif', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  h1, h2, h3, h4, h5, h6, p, a, pre, span, li, text, th, td, label {
    font-family: 'Spoqa Han Sans', 'Sans-serif';
  }

  strong, b {
    font-weight: 700;
    font-family: 'Spoqa Han Sans', 'Sans-serif';
  }

  input, textarea {
    user-select: text;
  }

  select, input, textarea {
    outline: none;
    font-family: 'Spoqa Han Sans', 'Sans-serif';
  }

  button {
    padding: 0;
    margin: 0;
    font-family: 'Spoqa Han Sans', 'Sans-serif';
    border: 0;
    outline: none;
    border-radius: 0;
    cursor: pointer;

    &:disabled {
      opacity: 1;
    }
  }
`;
