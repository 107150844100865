/**
 * @param {Array} data
 * @return {Object}
 * */
export const makeList = data => {
  const hasDong = data.filter(item => item.dong !== null);
  const emptyDong = data.filter(item => item.dong === null);

  const _sortHasDong = sortDong(hasDong);
  const _sortEmptyDong = sortDong(emptyDong);

  return {
    dongArray: _sortHasDong,
    emptyDongArray: _sortEmptyDong,
  };
};

/**
 * @description 건물 오름차순
 * @param {Array} building
 * @return {Array}
 * */
export function sortBuilding(building) {
  // return sortBy(building, ['buildingName']);
  return building;
}

/**
 * @description 동 오름차순, 층 오름차순, 호 오름차순, 동없음은 최하단 위치
 * @param {Array} list
 * @return {Array}
 * */
export function sortDong(list) {
  /*return list.sort((prev, next) => {
    if (prev.dong === next.dong) {
      if (prev.floor === next.floor) return prev.ho - next.ho;
      return prev.floor - next.floor;
    }
    return prev.dong - next.dong;
  });*/
  return list;
}

/**
 * @description 리스트에서 No.를 위한 유틸함수
 * */
export function pageListNo({ page, limit, index }) {
  return ((page - 1) * limit) + index + 1;
}

/**
 * @param {Object} list
 * @return {Boolean}
 * */
export function checkCountObjectToArray(list) {
  return Object.keys(list).reduce((acc, key) => {
    if (list.hasOwnProperty(key)) {
      const data = list[key];
      if (data.length > 0) acc.push(data);
    }
    return acc;
  }, []).length === 0;
}

export function checkCountObjectToArrayWithKey(list, prop) {
  return Object.keys(list).reduce((acc, key) => {
    if (list.hasOwnProperty(key)) {
      const data = list[key];
      if (data[prop].length > 0) acc.push(data);
    }
    return acc;
  }, []).length === 0;
}

export default {
  sortBuilding,
  makeList,
  pageListNo,
  checkCountObjectToArray,
  checkCountObjectToArrayWithKey
};
