import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import set from 'date-fns/set';
import isEqual from 'date-fns/isEqual';
import subDays from 'date-fns/subDays';

/**
 * date가 start와 end 사이의 값인가 체크하는 함수
 * @param {Date} date -> 검사할 날짜
 * @param {Date} start -> 시작일
 * @param {Date} end -> 종료일
 */
export function isBetween(date, start, end) {
  // isAfter(date, compare) date가 compare보다 이후인가
  // isBefore(date, compare) date가 compare보다 이전인가
  if (isAfter(date, start) && isBefore(date, end)) return true;
  return false;
}

export function setTime(date = new Date(), changeTimes = {}) {
  const { hours, minutes, seconds, milliseconds } = Object.assign(
    {},
    { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 },
    changeTimes,
  );

  return set(date, { hours, minutes, seconds, milliseconds });
}

/**
 * date 가 to 전이거나 같은 날인지 확인
 * @param {Date} date -> 검사할 날짜
 * @param {Date} to -> 범위 날짜
 */
export function isSameOrBefore(date, to) {
  if (isEqual(date, to) || isBefore(date, to)) return true;
  return false;
}

export function isForceBefore(date, to) {
  return isBefore(setTime(date), setTime(to));
}

/**
 * 받은 날짜(date)가 지정된 일 수 를 뺸 날짜에 포함되는지 체크하는 함수
 * @param {Date|String} date 날짜
 * @param {number} days 지정된 일 수
 */
export function isNew(date, days = 0) {
  if (!date) throw Error('date를 입력해주세요.');

  const now = setTime(new Date());
  const d = setTime(date instanceof Date ? date : !isNaN(new Date(date)) ? new Date(date) : now);

  if (isAfter(d, subDays(now, days))) return true;
  return false;
}
