export default {
  // 개인 + 기업
  type: 0,  // 0 : 개인 소유자, 1 : 법인 소유자, 2 : 법인 임대관리자
  typeName: '',
  seq: null,
  name: '',
  phone: '',

  // 기업
  businessClass: null,
  businessClassName: null,
  corpCeoName: null,
  corpName: null,
  corpSeq: null,
  corpType: null,
  corpTypeName: null,

  isMaster: false,

  // 플래그
  corpCertReceiptName: '',
  corpConversionReceiptName: '',
  usersWithdrawReceiptName: '',

  isCorpCertReceipt: false,
  isCorpConversionReceipt: false,
  isUsersBuildingAddMessage: false,
  isUsersWithdrawReceipt: false,

  loading: true,
  isLogged: false,
};
