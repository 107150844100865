export const BuildingFilter = [
  { label: '최근 업데이트 순', value: 0 },
  { label: '건물명 가나다 순', value: 1 },
];

export const householdFilter = [
  {
    value: '',
    label: '전체목록',
  },
  {
    value: '0',
    label: '입주중',
  },
  {
    value: '1',
    label: '계약만료예정',
  },
  {
    value: '2',
    label: '입주예정',
  },
  {
    value: '3',
    label: '공실',
  },
  {
    value: '4',
    label: '중개사공개',
  },
  {
    value: '5',
    label: '다방광고',
  },
];

export const certificationFilter = [
  {
    value: '0',
    label: '승인대기'
  },
  {
    value: '1',
    label: '승인완료'
  },
  {
    value: '2',
    label: '승인거절'
  },
  {
    value: '3',
    label: '전체'
  }
];
