import styled from 'styled-components';

export const Body = styled.div`
  padding: 50px;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  
  button + button {
    margin-left: 10px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ titleHeight }) => titleHeight};
  padding: 16px 0;
  background-color: #f2f4f8;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
`;
