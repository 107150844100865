import React, { useRef, useEffect, useCallback } from 'react';
import { string, number, bool, func, oneOfType } from 'prop-types';

import Portal from '../Portal';

import { Overlay, Content } from './styled';

const OverlayPannelComponent = ({
  children,
  width, hideOverlay, closeWithEsc,
  modalHandler,
}) => {
  const _wrapper = useRef(null);

  const onMouseDown = useCallback(e => {
    if (!hideOverlay && closeWithEsc && _wrapper.current && !_wrapper.current.contains(e.target)) {
      modalHandler();
      document.removeEventListener('mousedown', onMouseDown);
    }
  }, [closeWithEsc, hideOverlay, modalHandler]);

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [onMouseDown]);
  
  return (
    <Portal closeWithEsc={closeWithEsc} modalHandler={modalHandler}>
      <Overlay id="modalOverlay" hideOverlay={hideOverlay}>
        <div>
          <Content width={width} ref={_wrapper}>
            {children}
          </Content>
        </div>
      </Overlay>
    </Portal>
  );
};

OverlayPannelComponent.defaultProps = {
  width: 500,
  hideOverlay: false,
  closeWithEsc: false,
  modalHandler: false,
};

OverlayPannelComponent.propTypes = {
  hideOverlay: bool,
  closeWithEsc: bool,
  width: oneOfType([
    string,
    number,
  ]),
  modalHandler: oneOfType([
    bool,
    func,
  ]),
};

export default OverlayPannelComponent;
