import { css } from 'styled-components';

import SelectArrow from './images/select_arrow.svg';

const SelectStd = css`
  width: 154px;
  height: ${({ heightSize }) => heightSize ? `${heightSize}px` : '48px'};
  padding: 0 14px;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : '16px'};
  color: ${({ active }) => active ? '#2f4aa1' : '#666666'};
  border: 1px solid #d1d1d1;
  border-radius: 0;
  box-sizing: border-box;
  background-image: url(${SelectArrow});
  background-color: #fff;
  background-size: 10px;
  background-position: right ${({ padding }) => !!padding ? `${padding}px` : '15px'} center;
  background-repeat: no-repeat;
  outline: none;
  -webkit-appearance: none;
  transition: border 150ms ease-out;

  &:hover {
    border: 1px solid #444;
    cursor: pointer;
  }

  &::placeholder {
    color: #666;
  }

  &:focus {
    border: 1px solid #444;
    transition: border 150ms ease-in;
  }

  &::-ms-expand { // IE10 까지만 화살표 제거됨...
    display: none;
  }

  &:disabled {
    color: #888;
    background-color: #fafafa;
  }
`;

export default SelectStd;
