import styled, { css } from 'styled-components';

const ErrorText = styled.p`
  margin-top: 10px;
  ${props => props.isCenter && css`
    text-align: center;
  `}
  color: #d0021b;
  font-size: 14px;
  line-height: 20px;
`;

export default ErrorText;
