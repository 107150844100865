import Dropzone from 'dropzone';

class Uploader {
  constructor(options) {
    try {
      const {
        accept = (file, done) => done(),
        method, id, url, withCredentials = true,
        maxSize, clickable, multiUpload, autoProcessQueue, paramName, acceptedFiles, headers = {},
        dictInvalidFileType
      } = options;

      this.uploader = new Dropzone(id, {
        url: url,
        method: method || 'POST',
        withCredentials: withCredentials,
        maxFiles: multiUpload ? null : 1,
        maxFilesize: +maxSize || 10, // MB
        acceptedFiles: acceptedFiles || 'image/*',
        paramName: paramName || 'image', // The name that will be used to transfer the file
        accept,
        previewsContainer: false,
        dictFileTooBig: '파일크기 초과 (파일당 최대 {{maxFilesize}}MB)',
        dictInvalidFileType: dictInvalidFileType || 'png, jpg, jpeg, gif, pdf 확장자 파일만 선택해주세요.',
        clickable: clickable || null,
        autoProcessQueue: autoProcessQueue !== false,
        headers: {
          Accept: '*/*',
          'Cache-Control': null,
          'X-Requested-With': null,
          ...headers
        }
      });
      
    } catch(e) {
      console.error(`uploader를 생성중 에러가 발생했습니다.\nMESSAGE: ${e.message}`);
      console.log(`%c<< uploader options >>\n
options 타입 => object\n
{\n
    id: 버튼 || 박스 아이디 ex) #uploadBtn,   type = string\n
    url: APICALL URL,                     type = string'\n
    maxSize: 파일 최대 크기 (MB단위)        type = number\n
    multiUpload: true || false,           type = bool\n
      - true: 파일 여러개 전송가능\n
      - false: 파일 한개만 전송가능\n
}`, 'font-size: 15px;');
    }
  }
  
  init({ onAddedfile, onSending, onPreview, onProgress, onSuccess, onError, onComplete, onCanceled }) {
    const self = this;
    
    onAddedfile && this.uploader.on('addedfile', onAddedfile);
    onPreview && this.uploader.on('thumbnail', onPreview); // args (file, dataUrl)
    onSending && this.uploader.on('sending', onSending); // args (file, xhr, formData)
    onProgress && this.uploader.on('uploadprogress', onProgress);  // args (file, progress, bytesSent)
    onSuccess && this.uploader.on('success', onSuccess);  //  args (file, res)
    onCanceled && this.uploader.on('canceled', onCanceled); // args();
    onError && this.uploader.on('error', onError); // args (file, msg, res)
    
    this.uploader.on('complete', function(file) {
      self.uploader.removeFile(file);
      onComplete && onComplete(file);
    });
  }
  
  removeFile(file) {
    return this.uploader.removeFile(file);
  }
  
  processQueue() {
    this.uploader.processQueue();
  }
  
  getQueuedFiles() {
    return this.uploader.getQueuedFiles();
  }

  getUploadingFiles() {
    return this.uploader.getUploadingFiles();
  }

  getRejectedFiles() {
    return this.uploader.getRejectedFiles();
  }
  
  kill() {
    this.uploader && this.uploader.off();
    this.uploader && this.uploader.destroy();
  }
}

export default Uploader;
