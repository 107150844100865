function toMMSS(second = '0') {
  let sec_num = parseInt(second, 10); // don't forget the second param
  let minutes = Math.floor((sec_num) / 60);
  let seconds = sec_num - (minutes * 60);

  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;
  
  return `${minutes}:${seconds}`;
}

export default toMMSS;
