import { css } from 'styled-components';

import RadioOff from './images/radio_off.svg';
import RadioOn from './images/radio_on.svg';
import RadioDisabled from './images/radio_disabled.svg';

const RadioStd = css`
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  background-image: url(${RadioOff});
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  appearance: none;

  &:checked {
    background-image: url(${RadioOn});
  }

  &:disabled {
    background-image: url(${RadioDisabled});
  }
`;

export default RadioStd;
