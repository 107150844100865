import React from 'react';
import { useSelector } from 'react-redux';

import useUserApi from 'web~/hooks/useUserApi';

import store from '~/store';
import Alert from 'web~/components/Alert';
import { DabangBlueBtnLComponent as Button } from 'web~/components/Button';

const ErrorPopup = () => {
  const { dispatch } = store;
  const { logout } = useUserApi();
  const { msg, isError } = useSelector(({ error }) => error);

  async function onClickLogout() {
    await dispatch.error.setError({ code: '', msg: '', isError: false });
    await logout();
    window.location.href = '/signin';
  }

  const AlertBtn = () => <Button onClick={onClickLogout} label="확인" />;

  if (isError) {
    return (
      <Alert ButtonList={[AlertBtn]}>
        <p>{msg}</p>
      </Alert>
    );
  }

  return null;
};

export default ErrorPopup;
