import styled from 'styled-components';

export const Overlay = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background-color: ${props => props.hideOverlay ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.6)'};
  z-index: 1000;

  > div {
    display: table-cell;
    vertical-align: middle;
  }
`;

export const Content = styled.section`
  width: ${props => props.width ? `${props.width}px` : '500px'};
  margin: 5% auto;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: relative;
  z-index: 1001;
`;
