import { householdFilter } from '~/enum/filter';

export const initHouseholdFilter = {
  building: '',
  dong: '',
  floor: '',
  status: '',
  buildingList: [{ label: '등록된 건물이 없습니다.', value: '' }],
  dongList: [{ label: '전체 동', value: '' }],
  floorList: [{ label: '전체 층', value: '' }],
  statusList: householdFilter,
};

export const initHouseholdSummary = {
  depositFormat: '-',
  priceFormat: '-',
  gongsilCountFormat: '-',
  inCountFormat: '-',
  reserveCountFormat: '-',
  expireCountFormat: '-',
  vacancyCountFormat: '-',
  managers: '-',
};

export default {
  list: [],
  listRequest: [],
  isMore: false,
  page: 1,
  isBuildingManager: false,
  isShowGongsilActiveMessage: false,
  filter: initHouseholdFilter,
  summary: initHouseholdSummary,
};
