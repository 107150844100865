import { css } from 'styled-components';

export const TopTitle = css`
  height: 20px;
  font-size: 14px;
  color: #888;

  span {
    color: #444;
  }
`;

export const MainTitle = css`
  height: 28px;
  line-height: 28px;
  font-size: 20px;
  font-weight: 700;
  color: #222222;
`;

export default {
  TopTitle,
  MainTitle,
};

