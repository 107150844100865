import produce from 'immer';

import { removeDuplicate } from '~/utils/duplicate';
import state from './state';

export default {
  state,
  reducers: {
    setState(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    setBuildingSeq(state, buildingSeq) {
      return {
        ...state,
        buildingSeq,
        dongSeq: '',
        dongList: [],
        gongsilList: [],
        selectFloorList: [],
        selectHoList: {},
        keyword: '',
        selectManagerList: [],
        addedManagerList: [],
        selectAddedManagerList: [],
      };
    },
    setDongSeq(state, dongSeq) {
      return {
        ...state,
        dongSeq,
        selectFloorList: [],
        selectHoList: {},
        keyword: '',
        selectManagerList: [],
        addedManagerList: [],
        selectAddedManagerList: [],
      };
    },
    setSelectFloorList(state, list) {
      const { selectFloorList, selectHoList } = state;
      const { floor, floorFormat } = list;

      return {
        ...state,
        selectHoList: produce(selectHoList, draft => {
          if (draft.hasOwnProperty(floor)) {
            delete draft[floor];
          }
        }),
        selectFloorList: produce(selectFloorList, draft => {
          const floorIdx = draft.findIndex(
            item => (+item.floor === +floor) && (item.floorFormat === floorFormat),
          );

          if (floorIdx > -1) {
            draft.splice(floorIdx, 1);

          } else {
            draft.push(list);
          }
        }),
      };
    },
    setSelectHoList(state, gongsil) {
      const { selectHoList } = state;
      const { floor, ho, hoFormat } = gongsil;

      return {
        ...state,
        selectHoList: produce(selectHoList, draft => {
          if (draft.hasOwnProperty(floor)) {
            const idx = draft[floor].findIndex(item => item.ho === ho && item.hoFormat === hoFormat);

            if (idx > -1) {
              draft[floor].splice(idx, 1);

            } else {
              draft[floor].push(gongsil);
            }

          } else {
            draft[floor] = [gongsil];
          }
        }),
      };
    },
    setSelectManagerList(state, manager) {
      const { selectManagerList } = state;

      return {
        ...state,
        selectManagerList: produce(selectManagerList, draft => {
          const idx = draft.findIndex(({ usersIdx }) => usersIdx === manager.usersIdx);

          if (idx > -1) {
            draft.splice(idx, 1);
          } else {
            draft.push(manager);
          }
        }),
      };
    },
    setAddedManagerList(state) {
      const {
        selectHoList,
        selectManagerList, addedManagerList,
      } = state;

      return {
        ...state,
        selectFloorList: [],
        selectHoList: {},
        selectManagerList: [],
        addedManagerList: produce(addedManagerList, draft => {
          Object.keys(selectHoList).forEach(ho => {
            /** @type {Array} */
            const hoList = selectHoList[ho];

            hoList.forEach(ho => {
              const idx = draft.findIndex(({ gongsil }) => gongsil.seq === ho.seq);

              if (idx > -1) {
                draft[idx].users = removeDuplicate('usersIdx', [...draft[idx].users, ...selectManagerList]);

              } else {
                draft.push({
                  gongsil: ho,
                  users: Array.from(new Set(selectManagerList)),
                });
              }
            });
          });
        }),
      };
    },
    setSelectAddedManagerList(state, gongsil) {
      const { selectAddedManagerList } = state;
      const { seq, usersIdx } = gongsil;

      return {
        ...state,
        selectAddedManagerList: produce(selectAddedManagerList, draft => {
          if (draft.hasOwnProperty(seq)) {
            const idx = draft[seq].findIndex(idx => idx === usersIdx);

            if (idx > -1) {
              draft[seq].splice(idx, 1);

            } else {
              draft[seq] = Array.from(new Set([...draft[seq], usersIdx]));
            }

          } else {
            draft[seq] = [usersIdx];
          }
        }),
      };
    },
    removeAddedManagerList(state, list) {
      const { addedManagerList } = state;
      return {
        ...state,
        addedManagerList: produce(addedManagerList, () => {
          return list;
        }),
        selectAddedManagerList: {},
      };
    },
    resetAddedManager(state) {
      return {
        ...state,
        dongSeq: '',
        dongList: [],
        gongsilList: [],
        selectFloorList: [],
        selectHoList: {},
        keyword: '',
        managerList: [],
        selectManagerList: [],
        addedManagerList: [],
        selectAddedManagerList: {},
      };
    },
    resetPermission() {
      return state;
    },
  },
  effects: dispatch => {
    return {
      removeAddedManagerListEffects(action, { permission }) {
        const { addedManagerList, selectAddedManagerList } = permission;

        const keys = Object.keys(selectAddedManagerList);

        const temp = addedManagerList.map(({ gongsil, users }) => {
          const findId = keys.findIndex(key => +key === +gongsil.seq);

          if (findId > -1) {
            return {
              gongsil,
              users: users.filter(({ usersIdx }) => {
                return !selectAddedManagerList[keys[findId]].includes(usersIdx);
              }),
            };

          } else {
            return { gongsil, users };
          }
        });

        dispatch.permission.removeAddedManagerList(temp);
      },
    };
  },
};
