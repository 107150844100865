import Modal from './Module/Modal';
import ContentModal from './Module/ContentModal';
import OverlayPannel from './Module/OverlayPannel';
import {
  ModalTitleStyle,
  ModalSubTitleStyle,
} from './Styled';

export {
  Modal,
  ContentModal,
  OverlayPannel,
  
  ModalTitleStyle,
  ModalSubTitleStyle,
};
