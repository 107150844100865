import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const useGaHooks = () => {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;

    ReactGA.set({ page: pathname });
    ReactGA.send({ hitType: 'pageview', page: pathname });
    window.scrollTo(0, 0);
  }, [location]);
};

export default useGaHooks;
