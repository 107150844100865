import store from '~/store';

function useToast() {
  const { dispatch } = store;

  function toast(text, timeout = 2000) {
    if (!text || text === '') return;

    dispatch.toast.openToast({
      text,
      timeout,
    });
  }

  return toast;
}

export default useToast;
