/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';
import set from 'date-fns/set';
import addDays from 'date-fns/addDays';
import without from 'lodash/without';

import useApiCall from 'mobile~/hooks/useApiCall';

function useNoticePopup() {
  const { get } = useApiCall();
  const location = useLocation();

  const [active, setActive] = useState({});
  const [notices, setNotices] = useState([]);
  
  const isNotShow = location.pathname.indexOf('/cert-service') > -1;

  useEffect(() => {
    !isNotShow && getNotices();
  }, []);

  async function getNotices() {
    try {
      const res = await get(`/api/v3/board/notice/popup`, {
        platform: 1,
      });

      const list = res.reduce((r, i) => {
        const noShow = Cookie.get(i.seq);
        if (noShow) return r;
        r.push(i);
        return r;
      }, []);

      setNotices(list);
      setActive(list[0]);

    } catch(err) {
      console.log(err);
    }
  }

  function closeWithExpiredDate(seq, date) {
    const now = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    let d = now;
    if (date) {
      d = addDays(now, date);

    } else {
      d = set(now, { year: 9999 });
    }

    Cookie.set(seq, seq, { expires: d });

    close(seq);
  }

  function close(seq) {
    const item = notices.find(i => i.seq === seq);
    const list = without(notices, item);

    setNotices(list);
    setActive(list[0]);
  }

  return [active, notices, closeWithExpiredDate, close];
}

export default useNoticePopup;