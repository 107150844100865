import { css } from 'styled-components';

const DabangBlueBTN = css`
  border: 1px solid #326cf9;
  background-color: ${({ cancel }) => cancel ? '#fff':'#326cf9'};
  color: ${({ cancel }) => cancel ? '#326cf9':'#fff'};
`;

const ContentsBtn = css`

`;

const HouseholdBtn = css`
  width: ${({ width }) => width || 84}px;
  height: ${({ height }) => height || 42}px;
  border: solid 1px #aaaaaa;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  color: #222222;
`;

export default {
  DabangBlueBTN,
  ContentsBtn,
  HouseholdBtn,
};
