import { useRef, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import qs from 'query-string';

import store from '~/store';
import bridge from '~/utils/bridge';
import { deleteBlank } from '~/utils';

import useToast from './useToast';

function useApiCall() {
  const toast = useToast();
  const history = useHistory();
  const { dispatch } = store;
  const user = useSelector(({ user }) => user);

  const _apiCall = useRef(null);

  useEffect(() => {
    let apiCall = axios.create({
      headers: {
        csrf: 'token',
        Accept: 'application/json, text/plain, */*',
        'Cache-Control': 'no-cache',
        Expires: -1,
        Pragma: 'no-cache'
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
    
    apiCall.interceptors.request.use(reqConfig);
    apiCall.interceptors.response.use(handleSuccess, handleError);

    _apiCall.current = apiCall;
  }, []);
  
  function reqConfig(config) {
    if (['post', 'put', 'patch'].includes(config.method)) {
      config.data = deleteBlank(config.data);

    } else if (config.method === 'get') {
      config.params = deleteBlank(config.params);
    }
    
    return config;
  }

  function handleSuccess(response) {
    const { headers } = response;

    if (headers['corp-conversion-completed']) {
      const isShow = headers['corp-conversion-completed'] !== 'YES';

      dispatch.flag.setFlag({ flag: 'corpConvertComplete', isShow });
      
    } else if (headers['refresh-authorization']) {
      const token = headers['refresh-authorization'];
      bridge.ios(call => {
        call.callHandler('iOS Echo', {
          cmd: 'setToken',
          message: JSON.stringify({ token }),
        });
      });
    
      bridge.android(call => {
        call.setToken(token);
      });
      
      Cookie.set('jwt_token', token);
    }

    return response;
  }

  function handleError(error) {
    let err;

    try {
      err = error.response.data;

    } catch(e) {
      err = error;
    }
    
    switch (error.response.status) {
      case 400:
        if (err.code === 426) { // app 강제업데이트
          bridge.ios(call => {
            call.callHandler('iOS Echo', {
              cmd: 'needUpdate',
            });
          });

          bridge.android(call => {
            call.showVersionCheckDialog();
          });
        }

        if (err.code === 503) {  // 긴급점검
          dispatch.flag.setFlag({ flag: 'emergencyInspect', isShow: true });
        }

        break;

      case 401:
        if (err.code === 401) {  // 로그인 만료
          bridge.ios(call => {
            call.callHandler('iOS Echo', {
              cmd: 'removeToken',
            });
          });
        
          bridge.android(call => {
            call.removeToken();
          });

          Cookie.remove('jwt_token');
          
          dispatch.user.resetUser();
          toast(err.msg);

          setTimeout(() => {
            history.push({
              pathname: `/signin`,
              state: {
                modal: true,
              }
            });
          }, 33);
          
          return;
        
        } else if (err.code === 470) {  // 회원탈퇴
          if (user.id) {
            bridge.ios(call => {
              call.callHandler('iOS Echo', {
                cmd: 'removeToken',
              });
            });
          
            bridge.android(call => {
              call.removeToken();
            });
  
            Cookie.remove('jwt_token');

            dispatch.user.resetUser();
            toast(err.msg);
  
            setTimeout(() => {
              history.push({
                pathname: `/signin`,
                state: {
                  modal: true,
                }
              });
            }, 33);
            
            return;
            
          } else {
            toast(err.msg);

            history.push({
              pathname: '/',
            });

            return;
          }
        }

        break;

      case 500:
        if (typeof err === 'string') {
          err = { msg: err };

        } else if (err && err.msg) {
          err = { msg: err.msg || '에러가 발생하였습니다.' };
        
        } else {
          err = { msg: '#500 서버에러가 발생하였습니다.' };
        }
        break;

      default:
        if (err === null || !err.msg) {
          err = { msg: '서버에러가 발생하였습니다.' };
        }
    }

    return Promise.reject(err);
  }

  function checkToken() {
    const token = Cookie.get('jwt_token');
    const platform = Cookie.get('platform');
    const version = Cookie.get('version');
    const authConfirmParam = Cookie.get('safe_auth_confirm_param');
    let headers = {};

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (platform) {
      headers['App-Platform'] = platform;
    }

    if (version) {
      headers['App-Version'] = version;
    }

    if (authConfirmParam) {
      headers['Safe-Auth-Confirm'] = authConfirmParam;
    }

    return headers;
  }

  function get(path, params = {}) {
    return _apiCall.current.request({
      method: 'GET',
      url: `${path}`,
      params: params,
      headers: checkToken(),
      responseType: 'json',
    }).then(response => {
      return Promise.resolve(response.data.result, response.data.msg);
    });
  }

  function post(path, payload = {}) {
    return _apiCall.current.request({
      method: 'POST',
      url: path,
      headers: checkToken(),
      responseType: 'json',
      data: payload
    }).then(response => {
      return Promise.resolve(response.data.result, response.data.msg);
    });
  }

  return { get, post };
}

export default useApiCall; 
