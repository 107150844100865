import Cookie from 'js-cookie';

import store from '~/store';

function useUserApi() {
  const { dispatch } = store;

  async function getUser() {
    try {
      await dispatch.user.getUserInfo();

    } catch (err) {
      console.error(err);
    }
  }

  async function logout() {
    if (Cookie.get('jwt_token')) {
      try {
        await dispatch.user.logout();
        Cookie.remove('jwt_token');

      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  }

  return { getUser, logout };
}

export default useUserApi;
