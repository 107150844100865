import React, { useEffect } from 'react';
import {
  element, oneOfType,
  number, string, func, bool, arrayOf, node,
} from 'prop-types';

import Overlay from '../OverlayPannel';

import { Body, BtnWrap, Title } from './styled';

const ModalComponent = ({
  title, titleHeight, children,
  width, hideOverlay, closeWithEsc, ButtonList,
  modalHandler,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Overlay
      width={width}
      hideOverlay={hideOverlay}
      closeWithEsc={closeWithEsc}
      modalHandler={modalHandler}
    >
      {title && <Title titleHeight={titleHeight}>{title}</Title>}

      <Body>
        {children}
        {ButtonList && (
          <BtnWrap>
            {ButtonList.map((Component, key) => <Component key={key} />)}
          </BtnWrap>
        )}
      </Body>
    </Overlay>
  );
};

ModalComponent.defaultProps = {
  modalHandler: () => {},
  ButtonList: false,
};

ModalComponent.propTypes = {
  width: oneOfType([
    number,
    string,
  ]),
  hideOverlay: bool,
  closeWithEsc: bool,
  modalHandler: func.isRequired,
  ButtonList: oneOfType([
    bool,
    arrayOf(oneOfType([
      element,
      node,
      func,
    ]))
  ]),
};

export default ModalComponent;

