import React, { forwardRef } from 'react';
import { oneOfType, bool, func, string, number } from 'prop-types';

import {
  DabangBlueBtnS,
  DabangBlueBtnL,
  ContentsBtnXS,
  ContentsBtnS,
  ContentsBtnL,
} from './styled';

const commonDefaultProps = {
  label: '',
  onClick: () => {},
  cancel: false,
};

const commonPropTypes = {
  label: string.isRequired,
  cancel: bool,
  onClick: func,
  
  width: string,
  height: string,
  fontSize: string,
  fontWeight: oneOfType([
    string,
    number
  ]),
};

export const DabangBlueBtnSComponent = forwardRef(({ label, ...props }, ref) => {
  return <DabangBlueBtnS ref={ref} {...props}>{label}</DabangBlueBtnS>;
});

DabangBlueBtnSComponent.defaultProps = {
  ...commonDefaultProps,
  width: 'auto',
  height: '50px',
  fontSize: '17px',
  fontWeight: 600,
};

DabangBlueBtnSComponent.propTypes = {
  ...commonPropTypes,
};

export const DabangBlueBtnLComponent = ({ label, ...props }) => <DabangBlueBtnL {...props}>{label}</DabangBlueBtnL>;

DabangBlueBtnLComponent.defaultProps = {
  ...commonDefaultProps,
  width: '174px',
  height: '54px',
  fontSize: '16px',
  fontWeight: 700,
};

DabangBlueBtnLComponent.propTypes = {
  ...commonPropTypes,
};

export const ContentsBtnXSComponent = ({ label, ...props }) => <ContentsBtnXS {...props}>{label}</ContentsBtnXS>;

ContentsBtnXSComponent.defaultProps = {
  ...commonDefaultProps,
  width: '110px',
  height: '30px',
  backgroundColor: '#fff',
  fontSize: '12px',
  fontWeight: 400,
  color: '#343c53',
  hoverBoxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.18)',
  border: false,
  cursor: 'pointer',
};

ContentsBtnXSComponent.propTypes = {
  ...commonPropTypes,
  backgroundColor: string,
  color: string,
  hoverBoxShadow: string,
  border: bool,
};

export const ContentsBtnSComponent = forwardRef(({ label, ...props }, ref) => {
  return <ContentsBtnS ref={ref} {...props}>{label}</ContentsBtnS>;
});

ContentsBtnSComponent.defaultProps = {
  ...commonDefaultProps,
  width: '122px',
  height: '42px',
  fontSize: '14px',
  fontWeight: 400,
  hoverBoxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.18)',
  color: '#222',
};

ContentsBtnSComponent.propTypes = {
  ...commonPropTypes,
  hoverBoxShadow: string
};

export const ContentsBtnLComponent = ({ label, ...props }) => <ContentsBtnL {...props}>{label}</ContentsBtnL>;

ContentsBtnLComponent.defaultProps = {
  ...commonDefaultProps,
  width: '174px',
  height: '50px',
  fontSize: '14px',
  fontWeight: 700,
};

ContentsBtnLComponent.propTypes = {
  ...commonPropTypes,
};
