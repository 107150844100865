import styled from 'styled-components';

const Wrap = styled.div`
  margin: auto;
  font-size: 0;
`;

export const FullWrap = styled(Wrap)`
`;

export const SmallWrap = styled(Wrap)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
`;
