import { Component } from 'react';
import { createPortal } from 'react-dom';

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.className = props.className || 'modalOverlay';

    this.el.style['overflow'] = 'auto';
    this.el.style['position'] = 'fixed';
    this.el.style['top'] = '0';
    this.el.style['left'] = '0';
    this.el.style['right'] = '0';
    this.el.style['bottom'] = '0';
    this.el.style['z-index'] = '2000';
    this.el.style['background-color'] = 'rgba(0,0,0,0.6)';
  }

  componentDidMount() {
    const { closeWithEsc } = this.props;
    closeWithEsc && document.addEventListener('keydown', this.onKeyDown, false);
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    const { closeWithEsc } = this.props;
    closeWithEsc && document.removeEventListener('keydown', this.onKeyDown);
    document.body.removeChild(this.el);
  }

  onKeyDown = e => {
    if (e.key === 'Escape') {
      document.removeEventListener('keydown', this.onKeyDown);
      const { modalHandler } = this.props;
      if (modalHandler) {
        modalHandler();
      } else {
        document.body.removeChild(this.el);
      }
    }
  };

  render() {
    return createPortal(
      this.props.children,
      this.el,
    );
  }
}

export default Portal;
