import state from './state';

export default {
  state,
  reducers: {
    setFlag(state, { name, isShow, msg }) {
      return {
        ...state,
        [name]: isShow,
        msg: msg,
      };
    },
  },
};
