import React from 'react';

import { Modal } from 'web~/components/Modal';

import { Wrap } from './styled';

const AlertComponent = ({
  title, titleHeight,
  modalHandler, ButtonList, hideOverlay,
  children, width
}) => {
  return (
    <Modal
      title={title}
      titleHeight={titleHeight}
      width={width}
      hideOverlay={hideOverlay}
      closeWithEsc={false}
      modalHandler={modalHandler}
      ButtonList={ButtonList}>
      <Wrap>
        {children}
      </Wrap>
    </Modal>
  );
};

AlertComponent.defaultProps = {
  hideOverlay: false,
};

export default AlertComponent;
