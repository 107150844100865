/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga4';

import useGaHooks from '~/hooks/useGaHooks';
import useUserApi from 'web~/hooks/useUserApi';
import { CorpConvert, Emergency, Error, Notice } from 'web~/components/Popup';

import AuthRoutes from './auth';
import UnAuthRoutes from './unauth';

ReactGA.initialize([
  { trackingId: 'G-7N9GMJKVPT' },
  {
    trackingId: 'UA-167072277-1',
    gaOptions: {
      debug: false,
      titleCase: false,
    },
  }
]);

function Routes() {
  useGaHooks();
  const { getUser } = useUserApi();

  const { id, loading } = useSelector(({ user }) => user);

  const key = Cookie.get('jwt_token');

  useEffect(() => {
    if (key) getUser();
  }, [key]);

  if (key) {
    if (loading) {
      return (
        <>
          <CorpConvert />
          <Emergency />
          <Error />
          {/* <Loading /> */}
        </>
      );
    }

    if (id) {
      return (
        <>
          <AuthRoutes />
          <Notice />
        </>
      );
    }
  }

  return (
    <>
      <UnAuthRoutes />
      <Notice />
    </>
  );
}

export default Routes;
