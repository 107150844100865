function isEmpty(text) {
  if (text === '' || text === null) return true;
  return false;
}

function isArray(arr) {
  return Array.isArray(arr);
}

function isObject(obj) {
  return !Array.isArray(obj) && obj instanceof Object;
}

function checkArray(arr) {
  if (arr.length <= 0) return null;
  
  const result = arr.reduce((r, item) => {
    let data = item;
    
    if (isArray(item)) {
      data = checkArray(data);
    } else if (isObject(item)) {
      data = checkObject(data);
    }
    
    if (!isEmpty(data)) r.push(data);
    return r;
  }, []);
  
  if (result.length <= 0) return null;
  return result;
}

function checkObject(obj) {
  let temp = null;
  
  Object.keys(obj).forEach(name => {
    let data = obj[name];
    
    if (isArray(obj[name])) {
      data = checkArray(data);
    } else if (isObject(obj[name])) {
      data = checkObject(data);
    }
    
    if (!isEmpty(data)) {
      temp = {
        ...temp,
        [name]: data
      };
    }
  });
  
  return temp;
}

function deleteBlank(json) {
  return Object.keys(json).reduce((r, name) => {
    let data = json[name];
    
    if (isArray(json[name])) {
      data = checkArray(data);
      
    } else if (isObject(json[name])) {
      data = checkObject(data);
    }
    
    if (isEmpty(data)) return r;
    if (data === undefined) return r;
    r[name] = data;
    
    return r;
  }, {});
}

export default deleteBlank;