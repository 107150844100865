import { css } from 'styled-components';

export const InputStyled = css`
  width: 100%;
  height: 48px;
  padding: 0 14px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  color: #222;
  outline: none;
  transition: border 150ms ease-out;
  appearance: none;
  
  &::placeholder {
    color: #aaa
  }
  
  &:focus {
    border: 1px solid #444;
    transition: border 150ms ease-in;
  }

  &:disabled {
    background-color: #fafafa;
  }
`;

export default InputStyled;
