import alertMsg from './alertMsg';

function errorMsg(err, defaultMsg = '서버에러가 발생하였습니다.') {
  let msg = '';
  
  try {
    msg = err.msg;

  } catch(err) {
    msg = defaultMsg;
  }

  alertMsg(msg);
  return msg;
}

export default errorMsg;
