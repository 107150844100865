import { css } from 'styled-components';

export const ModalTitleStyle = css`
  color: #222;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.4px;
`;

export const ModalSubTitleStyle = css`
  color: #585858;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.3px;
`;