import styled  from 'styled-components';
import { ButtonStyled } from 'web~/components/Styled';

const Btn = styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: 'Spoqa Han Sans', 'Sans-serif', serif;
  
  & + button {
    margin-left: 8px;
  }

  &:disabled {
    opacity: 0.4;
  }
`;

export const DabangBlueBtnS = styled(Btn)`
  ${ButtonStyled.DabangBlueBTN};
  
  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
  }
`;

export const DabangBlueBtnL = styled(Btn)`
  ${ButtonStyled.DabangBlueBTN};
  
  box-shadow: ${props => props.cancel ? `0 5px 10px 0 rgba(39, 103, 207, 0.2)`:`0 5px 12px 0 rgba(35, 86, 208, 0.35)`};
  &:hover {
    box-shadow: ${props => props.cancel ? `0 5px 11px 0 rgba(65, 97, 151, 0.46)`:`0 5px 12px 0 rgba(65, 97, 151, 0.7)`};
  }
`;

export const ContentsBtnXS = styled(Btn)`
  border: ${props => props.border ? '1px solid #aaa':''};
  background-color: ${props => props.backgroundColor};
  opacity: ${props => props.opacity};
  color: ${props => props.color};
  cursor: ${props => props.cursor};
  &:hover {
    box-shadow: ${props => props.hoverBoxShadow};
  }
`;

export const ContentsBtnS = styled(Btn)`
  border: 1px solid #aaa;
  background-color: #fff;
  color: ${props => props.color};
  &:hover {
    color: ${props => props.hoverColor};
    background-color: ${props => props.hoverBackgroundColor};
    box-shadow: ${props => props.hoverBoxShadow};
  }
`;

export const ContentsBtnL = styled(Btn)`
  border: 1px solid ${props => props.cancel ? '#aaa':'#2f4aa1'};
  background-color: ${props => props.cancel ? '#fff':'#2f4aa1'};
  color: ${props => props.cancel ? '#222':'#fff'};
  box-shadow: ${props => props.cancel ? `0 5px 10px 0 rgba(39, 103, 207, 0.2)`:`0 5px 12px 0 rgba(35, 86, 208, 0.35)`};
  &:hover {
    box-shadow: ${props => props.cancel ? `0 3px 4px 0 rgba(0, 0, 0, 0.18)`:` 0 4px 5px 0 rgba(0, 0, 0, 0.3)`};
  }
`;
