import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { CorpConvert, Emergency, Error } from 'web~/components/Popup';
import { FullPageLoadingComponent as Loading } from 'web~/components/Loading';

// 인트로
const Intro = lazy(() => import(/* webpackChunkName: "intro" */ '../containers/Intro'));
// 회원가입
const SignUp = lazy(() => import(/* webpackChunkName: "sign-up" */ '../containers/SignUp'));
// 마이그레이션
const Migrate = lazy(() => import(/* webpackChunkName: "migrate" */ '../containers/Migrate'));
// 로그인
const SignIn = lazy(() => import(/* webpackChunkName: "sign-in" */ '../containers/SignIn'));
// 이용약관
const Terms = lazy(() => import(/* webpackChunkName: "terms" */ '../containers/Terms'));
// // 홈
// const Home = lazy(() => import(/* webpackChunkName: "home" */ '../containers/Home'));
// 아이디 찾기
const FindId = lazy(() => import(/* webpackChunkName: "find-id" */'../containers/FindId'));
// 비밀번호 찾기
const FindPassword = lazy(() => import(/* webpackChunkName: "find-password" */'../containers/FindPassword'));

const UnAuthRoutes = () => {
  return (
    <>
      <CorpConvert />
      <Emergency />
      <Error />

      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/intro" component={Intro} />
          <Route exact path="/signup/:path?" component={SignUp} />
          <Route exact path="/migrate/:path?" component={Migrate} />
          <Route exact path="/signin" component={SignIn} />
          <Route path="/find-id" component={FindId} />
          <Route path="/find-password/:path?" component={FindPassword} />
          <Route path="/terms/:type?" component={Terms} />
          <Redirect to="/intro" />
        </Switch>
      </Suspense>
    </>
  );
};

export default UnAuthRoutes;
