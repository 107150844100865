import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { CorpConvert, Emergency, Error, ResetPassword } from 'web~/components/Popup';
import { FullPageLoadingComponent as Loading } from 'web~/components/Loading';

// 홈
const DashBoard = lazy(() => import(/* webpackChunkName: "dashboard" */ '../containers/DashBoard'));
// 아이디 찾기
const FindId = lazy(() => import(/* webpackChunkName: "find-id" */'../containers/FindId'));
// 비밀번호 찾기
const FindPassword = lazy(() => import(/* webpackChunkName: "find-password" */'../containers/FindPassword'));
// 세대관리
const HouseHold = lazy(() => import(/* webpackChunkName: "household" */'../containers/HouseHold'));
// 건물관리
const Building = lazy(() => import(/* webpackChunkName: "building" */'../containers/Building'));
// 관리계정
const Permission = lazy(() => import(/* webpackChunkName: "permission" */ '../containers/Permission'));
// 정보수정
const MyAccount = lazy(() => import(/* webpackChunkName: "my-account" */'../containers/MyAccount'));
// 고객센터
const Service = lazy(() => import(/* webpackChunkName: "service" */'../containers/Service'));
// 이용약관
const Terms = lazy(() => import(/* webpackChunkName: "terms" */ '../containers/Terms'));
//승인관리
const Approval = lazy(() => import(/* webpackChunkName: "service" */'../containers/Approval'));
//사용자인증
const Certification = lazy(() => import(/* webpackChunkName: "service" */'../containers/Certification'));

const AuthRoutes = () => {
  return (
    <>
      <CorpConvert />
      <Emergency />
      <Error />
      <ResetPassword />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={DashBoard} />
          <Route path="/building/:path?" component={Building} />
          <Route path="/household/:path?/:seq?" component={HouseHold} />
          <Route path="/service/:tab?/:path?" component={Service} />
          <Route path="/find-id" component={FindId} />
          <Route path="/find-password/:path?" component={FindPassword} />
          <Route path="/my-account/:type?/:path?" component={MyAccount} />
          <Route path="/terms/:type?" component={Terms} />
          <Route path="/permission/:path?" component={Permission} />
          <Route path="/approval/:path?" component={Approval} />
          <Route path="/certification" component={Certification} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </>
  );
};

export default AuthRoutes;
