import bridge from './bridge';

function alertMsg(msg) {
  if (bridge.getPlatform() === 'web') {
    console.log(msg);

  } else {
    msg && alert(msg);
  }
}

export default alertMsg;