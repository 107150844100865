import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useUserApi from 'web~/hooks/useUserApi';

import store from '~/store';
import Alert from 'web~/components/Alert';
import { DabangBlueBtnLComponent as Button } from 'web~/components/Button';

const EmergencyPopup = () => {
  const { dispatch } = store;
  const history = useHistory();
  const { logout } = useUserApi();
  const { emergencyInspect, msg } = useSelector(({ flag }) => flag);

  function onClickLogout() {
    dispatch.flag.setFlag({ flag: 'emergencyInspect', isShow: false });
    logout();
    history.push({
      pathname: '/intro',
    });
  }

  const AlertBtn = () => <Button onClick={onClickLogout} label="확인" />;

  if (emergencyInspect) {
    return (
      <Alert ButtonList={[AlertBtn]}>
        <h1>긴급 점검</h1>
        <p>{msg}</p>
      </Alert>
    );
  }

  return null;
};

export default EmergencyPopup;
