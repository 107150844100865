import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { enableES5, enableMapSet } from 'immer';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from '~/store';
import Routes from './routes';
import GlobalStyled from './styled';

enableES5();
enableMapSet();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Routes />
    </Router>
    <GlobalStyled />
  </Provider>,
  document.getElementById('root')
);
