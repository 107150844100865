import state from './state';

export default {
  state,
  reducers: {
    setError(state, { code, msg, isError }) {
      return {
        ...state,
        code,
        msg,
        isError,
      };
    },
  },
};
