export default {
  title: '',
  isAlarm: false,
  isSettings: false,
  unprocess: {
    hasNotification: false,
    hasOwnerAuthentication: false,
    hasMasterApproval: false,
    notificationFormat: '-',
    ownerAuthenticationFormat: '-',
    masterApprovalFormat: '-',
  },
  prevLocation: {},
};
