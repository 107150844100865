export async function checkName(value, callback) {
  const flag = await (/^[a-zA-Z가-힣\s]{2,40}$/g).test(value);
  const msg = flag ? ``: `이름은 한글, 영문 2 ~ 40자 실명으로 입력해주세요.`;

  callback && await callback({ msg, flag });
  
  return { msg, flag };
}

export async function checkId(value, callback) {
  const flag = await (/^(?=.*[a-z])[a-z\d]{4,16}$/g).test(value);
  const msg = flag ? ``: `아이디는 4 ~ 16 사이의 영문(소문자)+숫자로 입력해주세요.`;
  
  callback && await callback({ msg, flag });

  return { msg, flag };
}

export async function checkEmail(value, callback) {
  const flag = await (/^[a-z0-9][a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/g).test(value);
  const msg = flag ? '' : '이메일 형식이 다릅니다. 다시 입력해주세요.';
  
  callback && await callback({ msg, flag });
  
  return { msg, flag };
}

export async function checkEmailId(value, callback) {
  const flag = await (/^[a-z0-9][a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*$/g).test(value);
  const msg = flag ? '' : '이메일 아이디를 확인해주세요.';

  callback && await callback({ msg, flag });

  return { msg, flag };
}

export async function checkEmailTail(value, callback) {
  const flag = await (/(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])$/g).test(value);
  const msg = flag ? '' : '이메일 주소를 확인해주세요.';

  callback && await callback({ msg, flag });

  return { msg, flag };
}

export async function checkPassword(value, callback) {
  const flag = await (/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()-])[a-zA-Z0-9~`!@#$%^&*()-]{8,16}$/g).test(value);
  const msg = flag ? ``: `비밀번호는 8자~16자 이내 영문+숫자+특수문자의 조합으로 입력해주세요.`;

  callback && await callback({ msg, flag });

  return { msg, flag };
}

export async function checkBirthday(value, callback) {
  const flag = await (/^(19\d{2}|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/g).test(value);
  const msg = flag ? ``: `생년월일을 숫자8자리 형식으로 작성해주세요.(예.19770501)`;

  callback && await callback({ msg, flag });

  return { msg, flag };
}

export async function checkBusinessId(value, callback) {
  const flag = await (/^[1-9]{1}[\d]{9}$/g).test(value);
  const msg = flag ? ``: `사업자등록번호 형식이 다릅니다. 다시 입력해주세요.`;

  callback && await callback({ msg, flag });

  return { msg, flag };
}

export async function checkPhone(value, callback) {
  const flag = await (/^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/g).test(value);
  const msg = flag ? ``: `휴대폰 번호 형식이 다릅니다. 다시 입력해주세요.`;

  callback && await callback({ msg, flag });

  return { msg, flag };
}

export default {
  checkName,
  checkId,
  checkEmail,
  checkEmailId,
  checkEmailTail,
  checkPassword,
  checkBirthday,
  checkBusinessId,
};
