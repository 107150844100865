import { css } from 'styled-components';

const CheckboxStd = css`
  width: 22px;
  height: 22px;
  margin: 0;
  padding: 0;
  background: #fff url(${require('./images/check_off.svg')}) center center no-repeat;
  background-size: cover;
  appearance: none;

  &:checked {
    background-image: url(${require('./images/check_on.svg')});
  }

  &:disabled {
    background-image: url(${require('./images/check_disabled.svg')});
  }
`;

export default CheckboxStd;
