import user from './user';
import form from './form';
import flag from './flag';
import error from './error';
import household from './household';
import toast from './toast';
import mobile from './mobile';
import permission from './permission';

export const models = {
  user,
  form,
  flag,
  error,
  household,
  toast,
  mobile,
  permission,
};
