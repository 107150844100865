import styled from 'styled-components';

export const Box = styled.div`
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Overlay = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  z-index: 1000;

  > div {
    display: table-cell;
    vertical-align: middle;
  }
`;

export const Content = styled.section`
  width: ${props => props.width ? `${props.width}px` : '500px'};
  margin: 5% auto;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: relative;
  z-index: 1001;
`;

export const Wrap = styled.div`
  padding-top: 30px;
  text-align: center;
`;

export const Text = styled.article`
  color: #222;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
`;