import styled from 'styled-components';

export const Overlay = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  background-color: ${props => props.hideOverlay ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.6)'};
  z-index: 2000;

  > div {
    display: table-cell;
    vertical-align: middle;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
`;

export const Title = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: ${({title}) => title === '' ? '#ffffff' : '#f4f6f9'};
  font-size: 18px;
  font-weight: 700;
  color: #222;

  > div:last-child {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  > svg {
    width: 35px;
    height: 30px;
  }
`;

export const Body = styled.div`
  padding: ${({ padding }) => padding ? padding : '0 40px'};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  height: 130px;
  button + button {
    margin-left: 10px;
  }
`;
