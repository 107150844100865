import React from 'react';

import { md } from '~/utils/mdToHtml';
import { ContentModal } from '~web/components/Modal';
import { DabangBlueBtnLComponent as Button } from 'web~/components/Button';

import useNoticePopup from './useNoticePopup';

import { Wrap , Text } from './styled';

const NoticePopupComponent = () => {
  const [active, notices, closeWithExpiredDate, close] = useNoticePopup();

  const ExtentionBtn = () => (
    <Button
      onClick={closeWithExpiredDate.bind(null, active.seq, active.not_see_date)}
      cancel={true}
      label={active.not_see_date ? `${active.not_see_date}일간 보지않기` : '다시보지 않기'} />
  );
  const Btn = () => <Button onClick={close.bind(null, active.seq)} label={'닫기'}/>;

  if (notices.length > 0) {
    return (
      <ContentModal
        modalHandler={close.bind(null, active.seq)}
        title={active.title}
        width="540px"
        ButtonList={[ExtentionBtn, Btn]}
      >
        <Wrap>
          <Text>{md(active.content)}</Text>
        </Wrap>
      </ContentModal>
    );
  }

  return null;
};

export default NoticePopupComponent;
