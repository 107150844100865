import { css } from 'styled-components';

const Textarea = css`
  padding: 14px;
  width: 100%;
  height: 60px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  resize: none;
  outline: none;
  font-size: 14px;
  transition: border 150ms ease-out;
  appearance: none;

  &:focus {
    border: 1px solid #444;
    transition: border 150ms ease-in;
  }

  &:placeholder {
    font-size: 14px;
    color: #9e9e9e;
  }
`;

export default Textarea;
